
@import url("https://fonts.googleapis.com/css2?family=Amiri:wght@400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');
body {
    font-family: "Amiri", serif;
    font-weight: 400;
}

$Allura: 'Allura', 'cursive';
// Used Color Variables

$themecolor: #1480CD;
$themefontColor: #ffffff;
$lspace: 0.24px;
$btnbg: #fff;
$btntext: #373b4d;
@mixin gradientcolor {
    background: #ffb3d5;
    background: -moz-linear-gradient(top, #ffb3d5 0%, #e580ff 100%);
    background: -webkit-linear-gradient(top, #ffb3d5 0%, #e580ff 100%);
    background: linear-gradient(to bottom, #ffb3d5 0%, #e580ff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffb3d5', endColorstr='#e580ff',GradientType=0 );
}
@mixin shadow1 {
    box-shadow: 0px 3px 6px 0 #0000001a;
}

.btn {
    // text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 700 !important;
    min-width: 100px;
    height: 35px;
    line-height: 1;
}
.btn-outline-secondary {
    color: #1a2126;
    border-color: #d9d9d9;
}
.btn-secondary {
    color: #fff;
    background-color: #6c757d69;
    border-color: #6c757d4a;
}
.btn-outline-primary {
    color: #1a2126;
    &:hover {
        color: #fff;
    }
}
.ql-editor {
    padding: 0 !important;
}

.form-control {
    font-size: 0.8rem;
}
