@import "./vars.scss";

body {
  background-color: #f5f7f9;
}

a {
  text-decoration: none !important;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  color: inherit;
}

a.ant-btn {
  padding-top: initial;
  line-height: initial;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  padding: 0;
  margin: 0;
}

p {
  margin: 0;
  padding: 0;
  font-weight: 400;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

figure {
  margin: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.backBtn {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-right: 14px;
  position: relative;
  top: -2px;
}

.passwordForm {
  display: flex;
  justify-content: center;
}

.errorDiv {
  display: flex;
  justify-content: center;
}

.textAlign {
  text-align: center;
}

.forgotPasswordForm {
  display: flex;
  justify-content: center;
}

.backForgotButton {
  margin-left: 15px;
}

.rowMargin {
  margin-top: 50px;
}

.buttonRight {
  margin-left: 15px;
}

.changePasswordHeaderStyle {
  text-align: center;
}

.readmoreBtn {
  background: none;
  border: none;
  padding: 0;
  color: #1780cd;
  font-weight: 700;
  margin-left: 5px;
}

.setupActnBtn {
  font-size: 12px;
  color: #ffffff;
  border-radius: 8px;
  padding: 5px 15px;
  height: 50%;
  font-weight: 700;
  margin-right: 12px;
  background-color: $themecolor;
  border: none;
  min-width: 106px;
  min-height: 36px;
  float: right;
  @include shadow1;

  @media (max-width: 991px) {
    min-width: auto;
  }
}

.setupEvntBtn {
  font-size: 14px;
  text-align: center;
  width: 110px;
  color: #ffffff;
  border-radius: 20px;
  padding: 12px 15px;
  height: 100%;
  font-weight: 700;
  margin-right: 30px;
  margin-left: 5px;
  background-color: $themecolor;
  border: none;
  min-width: 110px;
  min-height: 36px;
  @include shadow1;

  &.active {
    color: $themecolor;
    font-weight: 600;
  }

  @media (max-width: 991px) {
    min-width: auto;
  }
}

.errortxt {
  color: #fff;
  background: #f24423;
  padding: 2px 12px;
  border-radius: 5px;
  margin-top: 5px;
  position: relative;
  display: block;
  text-align: left;
  font-size: 14px;
}

.errorcontent {
  color: #f24423;
}

.fixedWidth {
  width: 20rem;
}

.marginleft {
  margin-left: 40px;
}

// CMN STYLES
.cmnBtn {
  color: #ffffff;
  height: auto;
  font-weight: 700;
  margin-left: auto;
  background-color: $themecolor;
  border: none;
  font-size: 13px;
  padding: 8px 20px;
  min-width: 130px;
  letter-spacing: 0.2px;
  border-radius: 100px;
  box-shadow: 0px 3px 12px 0 #00000029;

  @media (max-width: 1430px) {
    padding: 7px 20px;
  }

  @media (max-width: 991px) {
    min-width: auto;
  }

  &.no-shadow {
    box-shadow: none;
  }

  &.guestCriteria-add-btn[disabled] {
    color: #000000 !important;
    background-color: #fff !important;
  }

}

.no-colon {
  .ant-form-item-label>label:after {
    display: none;
  }

  .ant-form-item-label>label {
    height: 42px;
    margin-right: 10px;
  }
}

.outlineBtn {
  border: 1px solid #eeeeee;
  color: #000000;
  height: auto;
  font-weight: 700;
  font-size: 13px;
  padding: 7px 20px;
  min-width: 130px;
  letter-spacing: 0.2px;
  border-radius: 100px;

  @media (max-width: 1430px) {
    padding: 6px 20px;
  }

  @media (max-width: 991px) {
    min-width: auto;
  }
}

.grayBtn {
  font-size: 11px;
  color: #000000;
  font-weight: 700;
  background-color: #f1f1f1;
  border: none;
  border-radius: 5px;
  letter-spacing: 0.2px;
  padding: 5px 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  min-width: 106px;
  @include shadow1;

  @media (max-width: 991px) {
    min-width: auto;
  }
}

.blueBtn {
  font-size: 11px;
  color: $themefontColor;
  font-weight: 700;
  background-color: $themecolor;
  border: none;
  border-radius: 5px;
  letter-spacing: 0.2px;
  padding: 5px 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  @include shadow1;

  @media (max-width: 991px) {
    min-width: auto;
  }
}

.whiteBtn {
  color: #1480cd;
  height: auto;
  font-weight: 700;
  margin-left: auto;
  background-color: #fff;
  border: none;
  font-size: 13px;
  padding: 8px 20px;
  min-width: 130px;
  letter-spacing: 0.2px;
  border-radius: 100px;
  box-shadow: 0px 3px 12px 0 #00000029;

  @media (max-width: 1430px) {
    padding: 7px 20px;
  }

  @media (max-width: 991px) {
    min-width: auto;
  }

  &.no-shadow {
    box-shadow: none;
  }
}

.iconBtn {
  color: #5f717a;
  font-weight: 700;
  background-color: #ffffff;
  border: 1px solid #7070701a;
  letter-spacing: 0.2px;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  box-shadow: none;
  min-width: 37px;
  border-radius: 100px;
  font-size: 17px;

  @media (max-width: 991px) {
    min-width: auto;
  }

  &.active {
    border: none;
    color: $themefontColor;
    @include shadow1;
    background-color: $themecolor;
  }

  span+.anticon {
    margin-left: 0px;
  }

  &:hover {
    #Path_41488 {
      fill: #ffffff;
    }
  }
}

.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  text-decoration: none;
  background: #edb457;
  color: $themefontColor !important;
  border-color: #edb457;

  img {
    filter: brightness(0) invert(1);
  }
}

.ant-btn[disabled]:hover {
  border-color: #d9d9d9 !important;
  background: #f5f5f5 !important;
}

.icon-wrp {
  display: inline-flex;
  margin-right: 8px;
  margin-top: -3px;
}

.ant-dropdown {
  min-width: 130px !important;
  z-index: 1111;

  .ant-dropdown-menu-item {
    font-size: 12px;
    color: #000000;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      border-bottom: 1px solid #7070700d;
    }

    span {
      display: flex;
      align-items: center;
    }
  }

  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: #f5f5f500;
    color: $themecolor;

    a {
      color: $themecolor;
    }

    .fillcss {
      fill: $themecolor;
    }
  }
}

.bgthemecolor {
  fill: $themecolor;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
  position: relative;
  top: -5px;
}

.noData-wrp {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eef2f6;
  border-radius: 5px;
  flex-direction: column;
  padding: 20px;
  min-height: 20vh;
  height: auto;

  h4 {
    font-size: 18px;
    color: #000000;
    font-weight: 700;
    margin-bottom: 5px;
  }

  p {
    font-size: 14px;
    color: #000000;
  }
}

.buttonCheckGrp {
  .ant-checkbox {
    display: none;
  }

  .ant-checkbox-wrapper {
    font-size: 14px;
    color: #000000;
    background-color: #f1f1f1;
    border-radius: 5px;
    min-height: 44px;
    border: 1px solid #ff000000;
    padding: 5px 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    flex-basis: 0;

    &.ant-checkbox-wrapper-checked {
      border: 1px solid $themecolor;
    }

    @media (max-width: 1440px) {
      min-height: 40px;
    }
  }

  .ant-checkbox-wrapper+.ant-checkbox-wrapper {
    margin-left: 12px;
  }
}

.eqwidth {
  .ant-checkbox-group {
    width: 100%;
    display: flex;
  }
}

// status buttons
.stdone,
.checkin {
  color: #0bae36;
}

.checkout,
.stpending {
  color: #ff1616;
}

.stinprogress {
  color: #ff8800;
}

.streopen {
  color: #ff1616;
}

// status buttons
.mb-30 {
  margin-bottom: 30px;
}

.fullPageLoader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #ffffffc7;
  z-index: 111;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-message .anticon {
  top: -3px;
}

// CMN STYLES

// ...........................SIGNIN.............................

.container-wrp {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.logo {
  margin: 5% 0 5% 0;
  text-align: center;

  @media (max-width: 1280px) {
    img {
      width: 160px;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 20px;

    img {
      width: 100px;
    }
  }
}

.signin-wrp {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.bgPart {
  width: 55%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;

  &::after {
    position: absolute;
    content: "";
    background-image: url("../assets/background-images/auth-shape.png");
    width: 6.5%;
    height: 100%;
    right: 0px;
    background-size: 100% 100%;
    top: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.formPart {
  position: relative;
  padding: 20px 60px;
  display: flex;
  flex-direction: column;
  min-width: 560px;
  width: 45%;
  background-color: #fff;
  height: 100%;
  overflow: auto;

  h1 {
    font-size: 39px;
    color: #000000;
    font-weight: 700;
    position: relative;
    padding-bottom: 15px;
  }

  h6 {
    color: #00000070;
    font-size: 15px;
    margin-bottom: 40px;

    @media (max-width: 1430px) {
      margin-bottom: 40px;
    }

    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }

  h4 {
    color: #1fa1db;
    font-weight: 600;
    text-align: center;
    margin: 0 0 8% 0;
  }

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    background-color: #f6f6f6;
    border: 1px solid rgba(20, 128, 205, 0.2);
  }

  .ant-checkbox+span {
    font-size: 15px;
    color: #00000070;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $themecolor;
    border-color: $themecolor;
  }

  .form-wrap {
    width: 88%;
    max-width: 600px;
    margin: auto;

    @media (max-width: 1600px) {
      max-width: 420px;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .bgbtn {
    padding: 10px 20px;
  }
}

.sign-field {
  position: relative;

  input {
    border: 1px solid #1480cd33;
    background: #f6f6f600;
    border-radius: 5px;
    padding: 8px 13px;
    font-size: 14px;
    border-radius: 50px;
  }

  .ant-input-affix-wrapper.ant-input-password {
    border: 1px solid #1480cd33;
    background: #f6f6f600;
    padding: 0;
    font-size: 14px;
    border-radius: 50px;
    position: relative;

    input {
      padding: 8px 13px;
    }

    .ant-input-prefix,
    .ant-input-suffix {
      position: absolute;
      right: 12px;
      top: 12px;
    }
  }
}

.otpInput {
  .form-group {
    display: flex;
    justify-content: space-between;
  }

  .ant-row.ant-form-item {
    width: 20%;
  }

  input {
    border: 1px solid #1480cd33;
    background: #f6f6f600;
    border-radius: 5px;
    padding: 8px 13px;
    font-size: 14px;
    border-radius: 50px;
    text-align: center;
  }

  .ant-form-item-explain-error {
    display: none;
  }
}

.linkBtn {
  font-size: 15px;
  border: none;
  padding: 0;
  margin: 10px auto;
  display: block;
  background: none !important;
  color: #1480cd;
  box-shadow: none;

  &:hover {
    background: none;
    color: #edb457 !important;
  }

  &.ant-btn[disabled]:hover {
    background: none !important;
    color: rgba(0, 0, 0, 0.25) !important;
  }
}

.continuebtn {
  font-size: 20px;
  color: #ffffff;
  border-radius: 5px;
  margin-top: 30px;
  padding: 12px 13px;
  height: auto;
  line-height: normal !important;
  border: none;
  text-transform: uppercase;
  background-color: #ff8cc0;
}

.ant-btn.continuebtn {
  &:active {
    background-color: #ff8cc0 !important;
    color: #ffffff;
  }
}

.forgotlink {
  font-size: 15px;
  color: #00000070;
  margin-top: 4px;
}

.figureStyle {
  width: 100% !important;
  color: #fff;
}

.headerName {
  margin-top: 10px;
}

.ant-alert {
  align-items: baseline !important;
}

.ant-form-item-explain-error {
  color: #ff4d4f;
  margin-left: 14px;
}

.ant-form-item-has-error .quillEditor-wrp .quill {
  background: #fbe8e8;
  border-color: #ff4d4f;
}

.ant-form-item-has-error .ant-input:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error .ant-input:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fbe8e8;
  border-color: #ff4d4f;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fbe8e8;
  border-color: #ff4d4f;
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fbe8e8;
  border-color: #ff4d4f;
}

.ant-form-item-explain.ant-form-item-explain-error {
  padding: 2px 12px;
  border-radius: 5px;
  display: inline-flex;
  width: auto;
  margin-top: 5px;
  position: relative;
  background: #f30;
  color: #fff;

  &:after {
    content: "";
    position: absolute;
    top: -7px;
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
  }
}

input:-internal-autofill-selected {
  background-color: #f6f6f6 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f6f6f6 inset !important;
  box-shadow: 0 0 0 30px #f6f6f6 inset !important;
}

.tooltip-wrp {
  cursor: pointer;
}

.errorTooltip {
  .ant-tooltip-content {
    margin-right: -18px;
  }

  .ant-tooltip-inner {
    background-color: #1480cd;
    border-radius: 5px;
  }

  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: #1480cd;
  }
}

.tooltipPosition {
  position: absolute;
  top: 7px;
  right: 13px;
  text-align: center;
}

// ...........................SIGNIN.............................

// ...........................HOTEL MANAGEMENT.............................

header {
  background-color: $themecolor;
  padding: 8px 20px;
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 1111;
}

.headericons-grp {
  &>ul {
    display: flex;

    &>li {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-left: 25px;
    }
  }

  .notification-wrp {
    .notificationCount {
      font-size: 8px;
      color: #ffffff;
      background-color: #ffffff;
      position: absolute;
      top: 5px;
      left: 7px;
      padding: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      width: 10px;
      height: 10px;
      border: 2px solid #1480cd;
    }
  }
}

.notificationPop {
  &.ant-btn {
    background: #1890ff00 !important;
    border-color: #1890ff00 !important;
    text-shadow: none !important;
    box-shadow: none !important;
    padding: 0;
    line-height: initial;
    font-size: initial;
    height: auto;

    &:hover img,
    &:focus img,
    &:active img {
      filter: brightness(1) invert(0);
    }
  }
}

.notificationHead {
  background-color: #2c9fda;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;

  h4 {
    font-size: 18px;
    color: #ffffff;
    font-weight: 700;
  }

  button {
    background: none;
    font-size: 14px;
    color: #ffffff;
    border: none;
  }
}

.notificationDropdown {
  border-radius: 3px;
  overflow: hidden;
  @include shadow1();

  ul {
    width: 320px;
    max-width: 100%;
    max-height: 355px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: #a4a4a538;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #a6a6a6;
      border-radius: 3px;
    }

    li {
      display: flex;
      font-size: 12px;
      color: #5a5a5a;
      border-bottom: 1px solid #00000012;
      padding: 15px 20px;

      figure {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 20px;
        flex: none;

        img {
          border-radius: 50%;
        }
      }

      span {
        display: block;
        font-size: 10px;
        color: #a4a4a5;
        margin-top: 5px;
      }

      &:last-child {
        border-bottom: none;
      }

      &.new {
        background-color: #1680cd24;
      }
    }
  }

  .ant-popover-inner-content {
    padding: 0;
  }

  .ant-popover-content>.ant-popover-arrow {
    right: 6px;
  }
}

.headerUser {
  display: flex;
  align-items: center;

  figure {
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }

  .ant-dropdown-link {
    font-size: 19px;
    font-weight: 700;
    margin-left: 15px;
    display: flex;
    align-items: center;

    .anticon {
      font-size: 12px;
    }
  }

  .dropdown-wrp {
    color: #fff;
  }
}

.cmnBtn:hover {
  color: #fff;
}

.sideMenu {
  box-shadow: 0px 3px 6px 0 #0000000d;
  width: 306px;
  background: #fff;
  padding: 30px 0px 30px 20px;
  height: calc(100vh - 68px);
  overflow-x: auto;
  position: fixed;
  top: 68px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1430px) {
    padding: 28px 0px 28px 20px;
  }

  .ant-menu-item {
    padding: 5px 0;
    font-size: 14px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    height: auto !important;
    margin: 0 !important;
    padding-left: 16px !important;

    img {
      margin-top: -5px;
      margin-right: 10px;
      max-width: 16px;
    }
  }

  .ant-menu-sub {
    padding-left: 20px !important;
  }

  .ant-menu-submenu-title {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    height: auto !important;
    padding-left: 16px !important;

    img {
      margin-top: -5px;
      margin-right: 20px;
    }
  }

  .ant-layout-sider {
    width: 100% !important;
    min-width: 100% !important;
    background: #ffffff;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: $themecolor;
    color: $themefontColor !important;

    a {
      color: $themefontColor !important;
    }

    img {
      filter: brightness(0) invert(1);
    }
  }

  .ant-menu-item-selected::after {
    display: none !important;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #a4a4a538;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a6a6a6;
    border-radius: 3px;
  }
}

.logoutbtn {
  font-size: 19px;
  color: #78787d;
  background: none;
  border: none;
  position: absolute;
  bottom: 0;
  left: 20px;

  img {
    margin-right: 20px;
  }
}

.mainContent {
  padding: 66px 0px 0px 306px;

  .mainContent-in {
    padding: 30px 30px 45px 30px;
  }
}

.Pagenamecard-wrp {
  background-image: url("../assets/background-images/pageheadbglayer.png");
  background-size: cover;
  background-color: $themecolor;
  display: flex;
  border-radius: 5px;
  justify-content: space-between;
  padding: 0px 15px 0px 30px;
  height: 110px;
  align-items: center;
  margin-bottom: 30px;
  @include shadow1();

  h1 {
    font-size: 40px;
    color: #ffffff;
    font-family: $Allura;
  }

  h6 {
    font-size: 14px;
    color: #ffffff8a;
  }

  .ant-breadcrumb>span {
    font-size: 14px;
    color: #ffffff8a;
  }

  .ant-breadcrumb-separator {
    color: #ffffff8a;
    margin: 0 3px;
  }

  figure {
    display: flex;
    align-self: flex-end;
  }
}

.tablefilter-wrp {
  margin-bottom: 30px;

  @media (max-width: 1430px) {
    .searchbox {
      .ant-input-group .ant-input {
        height: 36px;
      }

      .ant-input-affix-wrapper {
        .ant-input {
          height: 28.5px;
        }
      }
    }

    .cmnSelect-form {
      .ant-select-selector {
        height: 36px !important;
      }

      .ant-picker {
        height: 36px;
      }
    }
  }
}

.searchbox {
  .ant-input-group {
    overflow: hidden;
    box-shadow: 0px 3px 24px 0px #0000000a;
    border-radius: 30px;
  }

  .ant-input-group .ant-input {
    height: 40px;
    border: none;
    font-size: 14px;
    color: #78787d;
  }

  .ant-input-affix-wrapper {
    border: none;
    padding: 4px 15px;

    .ant-input {
      height: 32px;
    }
  }

  .ant-input-search-button {
    position: absolute;
    right: 6px;
    top: 0;
    bottom: 0;
    margin: auto;
    border: none;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .anticon {
      font-size: 15px;
    }
  }

  .ant-input-suffix {
    margin-right: 35px;
  }

  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    background: #edb45700;
    color: #000 !important;
  }
}

.cmnSelect-form {
  .ant-select {
    width: 100%;
  }

  .ant-select-selector {
    height: 40px !important;
    display: flex;
    align-items: center;
    border: none !important;
    color: #78787d;
    font-size: 11px;
    padding: 0 15px !important;
    box-shadow: 0px 3px 24px 0px #0000000a;
    border-radius: 30px !important;
  }

  .ant-picker {
    border-radius: 30px !important;
    overflow: hidden;
    box-shadow: 0px 3px 24px 0px #0000000a;
    border: none;
    height: 40px;
    width: 100%;

    input {
      color: #78787d;
      font-size: 14px;

      &::-webkit-input-placeholder {
        color: #78787d;
      }

      &:-ms-input-placeholder {
        color: #78787d;
      }

      &::placeholder {
        color: #78787d;
      }
    }
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-left: 0;
  }
}

.countrySelect-form {
  .ant-select {
    width: 21%;
  }

  .ant-select-selector {
    height: 40px !important;
    display: flex;
    align-items: center;
    border: none !important;
    border-radius: 5px !important;
    color: #78787d;
    font-size: 11px;
    padding: 0 20px !important;
    @include shadow1();
  }

  .ant-picker {
    border-radius: 5px;
    overflow: hidden;
    @include shadow1();
    border: none;
    height: 40px;
    width: 100%;

    input {
      color: #78787d;
      font-size: 11px;

      &::-webkit-input-placeholder {
        color: #78787d;
      }

      &:-ms-input-placeholder {
        color: #78787d;
      }

      &::placeholder {
        color: #78787d;
      }
    }
  }
}

.horizontal-form {
  display: flex;
  align-items: center;

  @media (max-width: 991px) {
    margin-top: 10px;
  }

  @media (max-width: 567px) {
    display: block;
  }

  label {
    flex: none;
    margin-right: 10px;
    font-size: 14px;
    color: #000000ba;
  }
}

.adduserbtn {
  @include shadow1();
  border: none;
  border-radius: 5px;
  background-color: #fff;
  height: 40px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 40px;

  @media (max-width: 1430px) {
    height: 36px;
  }
}

.table-wrp {
  .ant-table-body {
    border: 1px solid rgba(0, 0, 0, 0.0705882353);
  }

  .ant-table {
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: none;
    min-height: 60vh;

    @media (max-width: 767px) {
      min-height: initial;
    }

    table {
      border-collapse: collapse;
      border-radius: 0;
    }
  }

  .ant-table-thead>tr>th {
    color: #000000;
    background: #e7f2f7;
    border: none;
    font-size: 12px;
    padding: 8px 10px;
    height: 50px;
    font-weight: 700;

    &::before {
      display: none;
    }

    // &:first-child {
    //   border-top-left-radius: 6px !important;
    // }
    // &:last-child {
    //   border-top-right-radius: 6px !important;
    // }
  }

  .ant-table-tbody>tr>td {
    font-size: 14px;
    color: #000000;
    background: #f5f7f9;
    padding: 5px 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.0705882353) !important;
    height: 48px;

    small {
      margin-left: 5px;
      font-weight: 700;
      color: #00000099;
    }
  }

  .actionIcons {
    color: $themecolor;
  }

  .ant-table-header {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
  }

  .ant-table-body {
    overflow-y: auto !important;

    table {
      -webkit-border-vertical-spacing: 5px !important;
    }

    tr {
      box-shadow: 0px 10px 30px #868e961a;

      &.disabled {
        td {
          color: #dbdbdb;
        }

        .viewlink {
          color: #dbdbdb;
        }

        h6 {
          color: #dbdbdb;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: #a4a4a538;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #a6a6a6;
      border-radius: 3px;
    }
  }

  .ant-table-cell-scrollbar {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .ant-rate {
    font-size: 14px;
  }

  .ant-rate-star:not(:last-child) {
    margin-right: 4px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding-left: 0;
  }

  .cmn-input {
    .ant-form-item {
      margin: 0;
    }

    input {
      background: #ffffff;
    }
  }

  &.fixed-table {
    th {
      &.ant-table-cell-fix-right-first {
        right: 1px !important;
      }
    }
  }
}

.no-bg-table {
  margin-bottom: 15px;

  .ant-table-tbody>tr>td {
    background: #ffffff;
    color: #000000;
  }
}

.no-minHeight-table {
  &.table-wrp .ant-table {
    min-height: auto;
  }
}

.expandable-table {
  .ant-table-header {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
  }

  .ant-table-thead>tr>th {
    color: #ffffff;
    background: #0091d4;
  }

  .ant-table-body tr {
    box-shadow: none;
  }

  .ant-table-tbody>tr.ant-table-row-level-0 {
    td {
      background: #e7f2f7;
    }
  }

  .ant-table-row-indent+.ant-table-row-expand-icon {
    margin-top: 0;
    background: #e7f2f7;
    border: 1px solid #71abc580;
    color: #64767e;
  }

  .ant-table-row:not(.ant-table-row-level-0) {
    .ant-table-cell-with-append {

      .ant-table-row-expand-icon,
      .ant-table-row-indent {
        display: none;
      }
    }
  }
}

.auto-width {
  .ant-table-body table {
    table-layout: unset !important;
  }

  .ant-table-tbody>tr>td {
    min-width: 150px;
  }
}

.tableHead {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
  flex-wrap: wrap;

  h3 {
    margin: 0 !important;
  }

  span {
    font-size: 14px;
    color: #000000cc;
  }
}

.viewlink {
  font-size: 12px;
  color: #1680cd;
  font-weight: 700;
  text-decoration: underline !important;
  margin: 0 6px;

  &.disabled {
    color: #dbdbdb;
  }
}

.activeStatus {
  color: #0bae36;
}

.inactiveStatus {
  color: #ff1616;
}

.tableactionbtn-grp {
  display: flex;
  align-items: center;

  button:not(.ant-switch) {
    background: none;
    border: none;
    flex: none;
  }

  a {
    padding: 1px 6px;
  }

  button:disabled {
    cursor: not-allowed;

    img {
      filter: grayscale(4%) contrast(2%);
      -webkit-filter: grayscale(4%) contrast(2%);
      -moz-filter: grayscale(4%) contrast(2%);
    }
  }

  .ant-switch {
    margin: 1px 6px;
  }

  .ant-btn:hover img,
  .ant-btn:focus img,
  .ant-btn:active img {
    filter: brightness(1) invert(0);
  }
}

//Tab style

.tabList {
  position: relative;
  margin-bottom: 25px;
  width: 100%;
  display: flex;
  align-items: flex-start;

  ul {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  li {
    display: inline-block;
    text-align: center;
    font-size: 13px;
    color: #000000;
    border-radius: 33px;
    padding: 7px 12px;
    cursor: pointer;
    height: 100%;
    font-weight: 700;
    margin-right: 12px;
    min-width: 118px;
    box-shadow: 0px 3px 12px 0 #00000003;
    background-color: #ffffff;
    border: 1px solid #7070701a;
    transition: all 0.3s;
    margin-bottom: 5px;

    @media (min-width: 1601px) {
      font-size: 15px;
      min-width: 125px;
    }

    @media (max-width: 1400px) {
      padding: 6px 12px;
      min-width: 100px;
      font-size: 13px;
    }

    &.active {
      background-color: #1480cd;
      color: #ffffff;
      box-shadow: 0px 3px 12px 0 #00000029;
    }

    &:hover {
      background-color: #1480cd;
      color: #ffffff;
      transition: all 0.3s;
    }

    @media (max-width: 767px) {
      margin-right: 4px;
    }
  }

  .iconBtn {
    width: 37px;
    flex: none;
  }

  &.combined {
    li {
      margin-right: 0;
      border-radius: 0;

      &:first-child {
        border-top-left-radius: 33px;
        border-bottom-left-radius: 33px;
      }

      &:last-child {
        border-top-right-radius: 33px;
        border-bottom-right-radius: 33px;
      }
    }
  }
}

.tableuser {
  display: flex;
  align-items: center;

  figure {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    // overflow: hidden;
    flex: none;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span {
    font-size: 12px;
    color: #000000;
  }
}

.iconbtn {
  background: #eeeeee;
  border-radius: 4px;
  border: none;
  width: 30px;
  height: 30px;
  @include shadow1;
}

.cmnModal {
  width: 580px !important;
  max-width: 95%;

  .ant-modal-body {
    padding: 24px 24px 0;
  }

  .ant-modal-header {
    border-bottom: 1px solid #f0f0f000;
    padding: 25px 24px;
    padding-bottom: 5px;

    .ant-modal-title {
      color: #000000;
      font-size: 22px;
      font-weight: 700;
    }
  }

  .ant-modal-close {
    background: #f5f5f5;
    border-radius: 5px;
    width: 26px;
    height: 26px;
    margin: 15px 20px;
    top: 5px;
    @include shadow1;

    .ant-modal-close-x {
      width: 100%;
      height: 100%;
      line-height: initial;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
    }
  }

  .ant-modal-content {
    border-radius: 5px;
    overflow: hidden;
  }

  .ant-modal-footer {
    border-top: none;
    padding: 10px 16px 30px;
  }
}

.successModal {
  .ant-modal-content {
    border-radius: 5px;
  }

  .ant-modal-close {
    background: #f5f5f5;
    border-radius: 5px;
    width: 26px;
    height: 26px;
    margin: 15px 20px;
    @include shadow1;

    .ant-modal-close-x {
      width: 100%;
      height: 100%;
      line-height: initial;
    }
  }
}

.deleteModal {
  &.ant-modal {
    width: 430px !important;
  }

  .ant-modal-header {
    display: none;
  }

  .ant-modal-close {
    display: none;
  }
}

.cmn-input {
  .ant-form-item {
    @media (max-width: 1440px) {
      margin-bottom: 16px;
    }
  }

  .ant-form-item-label {
    padding: 0;
  }

  label {
    color: #000000c7;
    font-size: 12px;
    padding-bottom: 3px;
  }

  input {
    border: 1px solid #1480cd33;
    background: #f6f6f600;
    border-radius: 5px;
    padding: 8px 15px;
    font-size: 14px;
    border-radius: 50px;
    color: #000000;

    &::-webkit-input-placeholder {
      color: #78787d;
    }

    &:-ms-input-placeholder {
      color: #78787d;
    }

    &::placeholder {
      color: #78787d;
    }
  }

  textarea {
    border: 1px solid #1480cd33;
    background: #f6f6f600;
    border-radius: 5px;
    padding: 8px 13px;
    font-size: 14px;
    border-radius: 22px;
    color: #000000;

    &::-webkit-input-placeholder {
      color: #78787d;
    }

    &:-ms-input-placeholder {
      color: #78787d;
    }

    &::placeholder {
      color: #78787d;
    }
  }

  .ant-input-affix-wrapper {
    border: 1px solid #1480cd33;
    background: #f6f6f600;
    border-radius: 5px;
    padding: 8px 13px;
    font-size: 14px;
    border-radius: 50px;
  }

  .ant-select {
    width: 100%;

    input {
      border-radius: 0;
    }

    .ant-select-selector {
      height: 40px !important;
      display: flex;
      align-items: center;
      color: #000000;
      font-size: 11px;
      padding: 0 15px !important;
      border-radius: 30px !important;
      border: 1px solid #1480cd33 !important;
      background: #f6f6f600 !important;
    }

    &.ant-select-status-error {
      .ant-select-selector {
        background-color: #fbe8e8 !important;
      }
    }
  }

  .ant-picker {
    padding: 0;
    background: #f6f6f6;
    border: none;
    border-radius: 8px;
    width: 100%;

    input {
      padding-right: 5px;
    }

    .ant-picker-suffix {
      margin-right: 10px;
    }

    .ant-picker-clear {
      margin-right: 10px;
    }
  }

  .ant-select-single .ant-select-selector .ant-select-selection-search-input {
    height: 100%;
    padding-left: 5px;
  }

  .ant-modal-body {
    padding: 24px 24px 10px;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-left: 0;
    color: #857892;
  }

  .ant-select-multiple .ant-select-selection-search {
    margin-inline-start: 3px;
  }

  .ant-select-multiple .ant-select-selection-item {
    font-size: 14px;
    border-radius: 25px;
  }

  .ant-select-multiple .ant-select-selection-placeholder {
    left: 17px;
    padding-left: 0;
    color: #857892;
    font-size: 14px;
  }
}

.ant-select-arrow {
  color: #000000bd;
}

.phone-input {
  .ant-input-affix-wrapper>input.ant-input {
    padding: 0;
    border: none;
    outline: none;
    border-radius: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px #ffffff inset !important;
  }

  input:-internal-autofill-selected {
    background-color: #ffffff !important;
  }

  .ant-input-affix-wrapper.ant-input-affix-wrapper-status-error {

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      box-shadow: 0 0 0 30px #fbe8e8 inset !important;
    }

    input:-internal-autofill-selected {
      background-color: #fbe8e8 !important;
    }
  }
}

.contact-input {
  .ant-form-item-label {
    color: #00000082;
    font-size: 12px;
    padding-bottom: 3px;
  }

  input {
    border: none;
    background: #f6f6f6;
    border-radius: 8px;
    padding: 11px 15px;
    font-size: 14px;
    color: #000000;

    &::-webkit-input-placeholder {
      color: #78787d;
    }

    &:-ms-input-placeholder {
      color: #78787d;
    }

    &::placeholder {
      color: #78787d;
    }
  }

  .ant-input-affix-wrapper {
    border: none;
    background: #f6f6f6;
    border-radius: 8px;
    padding: 11px 13px;
    font-size: 14px;
    color: #000000;
  }

  .ant-input-group-addon {
    background-color: #f6f6f6;
    border: none;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .ant-select-selector {
    height: 44px !important;
    align-items: center !important;
    border: none !important;
    border-radius: 8px !important;
    color: #000000;
    font-size: 14px;
    align-content: center;
    padding: 11px 14px !important;
    background: #f6f6f6 !important;
    margin-right: 20px;
    border-right: 1px solid #dddddd !important;
  }

  span.ant-select-selection-item {
    font-size: 12px !important;
    font-weight: 700;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-search-input {
    height: 100%;
  }
}

// RESPONSIVE
@media (max-width: 1430px) {
  .formPart h1 {
    font-size: 34px;
  }

  .forgotlink {
    font-size: 13px;
  }

  .formPart .ant-checkbox+span {
    font-size: 13px;
  }

  .sideMenu {
    width: 250px;
  }

  .sideMenu .ant-menu-submenu-title,
  .sideMenu .ant-menu-item {
    padding: 3px 0;
  }

  .mainContent {
    padding: 66px 0px 0px 250px;
  }
}

@media (max-width: 991px) {
  .formPart {
    padding: 20px 50px;
    min-width: 460px;
    width: 45%;
  }

  .table-wrp {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .signin-wrp {
    display: block;
    height: auto;
    min-height: 100vh;
  }

  .bgPart {
    width: 100%;
    height: 250px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .bgPart figure {
    max-width: 100%;

    img {
      max-width: 250px;
    }
  }

  .formPart {
    padding: 20px 25px;
    min-width: 100%;
    width: 45%;
  }

  .formPart h1 {
    font-size: 25px;
    margin-bottom: 15px;
  }

  .sign-field input {
    font-size: 16px;
    padding: 10px 13px;
  }

  .sign-field .ant-input-affix-wrapper {
    font-size: 16px;
    padding: 10px 13px;
  }

  // DASHBOARD
  header {
    padding: 5px 0px;
    min-height: 56px;
  }

  .headericons-grp>ul>li {
    margin-left: 10px;
  }

  .headerUser {
    figure {
      width: 30px;
      height: 30px;

      img {
        width: 100% !important;
        height: 100% !important;
      }
    }

    .ant-dropdown-trigger {
      .usernamespan {
        display: none;
      }
    }
  }

  .headerUser .ant-dropdown-link {
    font-size: 12px;
    margin-left: 0;
  }

  .mobmenuicon {
    .anticon {
      color: #fff;
    }
  }

  .mainContent {
    padding: 56px 0px 0px 0px;
  }

  .mainContent .mainContent-in {
    padding: 15px 15px 15px 15px;
  }

  .sideMenu {
    left: -250px;
    z-index: 111;
    top: 0;
    height: 100%;
    transition: left 0.3s;
    padding: 26px 0px 25px 15px;
    border-right: 1px solid #a6a6a6;

    &.open {
      left: 0px;
      transition: left 0.3s;
    }
  }

  .sideMenu .ant-menu-item {
    padding: 5px 0;
    font-size: 13px;
    padding-left: 9px !important;
    line-height: 30px !important;
  }

  button.menuclosebtn {
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #1680cd;
    height: 25px;
    line-height: 10px;
    color: #fff;
  }

  .headericons-grp {
    .notification-wrp {
      img {
        width: 14px;
      }
    }
  }

  // DASHBOARD
}

@media (max-width: 320px) {
  .bgPart {
    height: 195px;
  }
}

// RESPONSIVE

.contact-number input {
  margin-left: -4px;
}

.contact-number span.ant-input-group-addon {
  border: 0;
}

// .rc-virtual-list .rc-virtual-list-scrollbar {
//   display: block !important;
// }

.ant-select-selection-overflow {
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: nowrap;
  overflow-x: auto;
  max-width: 100%;
}

.dashboardLayout {
  min-height: 100vh;
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
}

// ---------------------  ADMIN ---------------------
.adminProfile {
  .settingsCard {
    padding: 10px 12px;

    @media (min-width: 568px) {
      flex-wrap: nowrap;
    }

    label {
      font-size: 12px;
    }

    .ant-form-item {
      margin: 0;
    }

    .ant-row {
      flex-flow: row;
    }

    .ant-row .ant-form-item-control {
      flex: none;
    }

    .ant-form-item-label {
      text-align: left;
      white-space: initial;
    }

    .cmn-input input {
      padding: 7px 15px;
    }
  }

  .profileCard {
    border-radius: 20px;
  }

  .createCardHead {
    border-bottom: 1px solid rgba(0, 0, 0, 0.0705882353);
    margin: 0px -25px 35px;
    display: flex;
    align-items: center;
    padding: 0 25px 25px;

    @media (max-width: 767px) {
      padding: 15px;
      margin: 0px -15px 15px;
    }
  }
}

// ---------------------  ADMIN ---------------------

// --------------------- SUB ADMIN ---------------------

.createCard {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 3px 6px 0 rgb(0 0 0 / 10%);

  .createCardHead {
    border-bottom: 1px solid #00000012;
    padding: 30px;
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      padding: 15px;
    }
  }

  .createCardBody {
    padding: 30px;

    @media (max-width: 767px) {
      padding: 15px;
    }
  }
}

.borderedBox {
  border: 1px solid #eeeeee;
  border-radius: 20px;
  padding: 25px;
  height: 100%;
  background: #fcfcfc;

  @media (max-width: 767px) {
    padding: 15px;
  }
}

.subadminProfileImageUpload {
  text-align: center;
  margin-bottom: 26px;

  figure {
    width: 128px;
    height: 128px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 20px;
  }

  h6 {
    font-size: 20px;
    color: #000000;
    font-weight: 700;
    margin: 5px 0;
  }

  .ant-upload {
    color: #1b80ce;
    font-weight: 700;
    font-size: 12px;
    text-decoration: underline;
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 128px;
    height: 128px;
    border-radius: 50%;
    overflow: hidden;
    margin: auto 0;
  }

  .ant-upload-list-picture-card-container {
    width: 128px;
    height: 128px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 20px;
  }

  .ant-upload-list-picture-card .ant-upload-list-item {
    border: none;
    padding: 0;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    object-fit: cover;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-info:before {
    left: 0;
  }

  .ant-upload-list-item-card-actions-btn:hover {
    background: none;
    border: none;
  }

  .ant-tooltip {
    display: none !important;
  }
}

.subadminAccessEdit-wrp {
  h4 {
    font-size: 20px;
    color: #000000;
    margin-bottom: 25px;
  }

  .ant-checkbox-wrapper {
    color: #000000;
    font-size: 13px;
    min-width: 23%;
  }
}

.checkboxGrp {
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 8px;

  .ant-form-item {
    margin-bottom: 0;

    .ant-checkbox-group {
      padding: 10px;
      width: 100%;
    }

    &:last-child {
      .ant-checkbox-group {
        border: 1px solid #eaeaea91;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }

    &:first-child {
      .ant-checkbox-group {
        background-color: #e7f2f791;
        border: none;
      }
    }
  }

  // .ant-checkbox-group {

  //   &:last-child {
  //     border: 1px solid #eaeaea91;
  //     border-bottom-left-radius: 10px;
  //     border-bottom-right-radius: 10px;
  //   }
  //   &:first-child {
  //     background-color: #e7f2f791;
  //     border: none;
  //   }
  // }
}

// toggle switch
.toggleSwitch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 20px;
  flex: none;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked+.slider {
    background-color: #2196f3;
  }

  input:focus+.slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked+.slider:before {
    transform: translateX(17px);
  }
}

// toggle switch
// --------------------- SUB ADMIN ---------------------

// --------------------- VENDOR ---------------------

// profile

.profileCard {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 25px;
  box-shadow: 0px 3px 6px 0 #0000000d;

  @media (max-width: 767px) {
    padding: 15px;
  }
}

.detailedContent {
  padding: 30px;

  @media (max-width: 767px) {
    padding: 15px;
  }

  p {
    font-size: 15px;
    color: #000000;
    margin-bottom: 15px;
  }

  h3 {
    font-size: 16px;
    color: #000000;
    font-weight: 700;
    margin-bottom: 15px;
  }
}

.profileCard-left {
  border: 1px solid #eeeeee;
  border-radius: 5px;
  background-color: #fcfcfc;
  padding: 30px;
  height: 100%;

  @media (max-width: 767px) {
    padding: 15px;
  }
}

.profileCard-right {
  border: 1px solid #eeeeee;
  border-radius: 5px;
  background-color: #fcfcfc;
  height: 100%;
}

.vendorCard {
  text-align: center;
  margin-bottom: 26px;

  figure {
    width: 128px;
    height: 128px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 20px;
    border: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h5 {
    font-size: 20px;
    color: #000000;
    font-weight: 700;
  }

  h6 {
    font-size: 13px;
    color: #78787899;
    margin: 8px 0 15px 0;
    max-width: 200px;
    overflow-wrap: break-word;
  }

  p {
    font-size: 15px;
    color: #000000;
    margin-bottom: 15px;
  }
}

.getcontactModal {
  width: 615px !important;
  max-width: 95%;
  label {
    font-size: 15px;
    color: #00000082;
  }

  p {
    font-size: 15px;
    color: #000000;
    margin: 10px 0 30px;
  }
}

.socialConnectList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0 30px;

  li {
    margin: 0px 10px 10px 0;
    &:last-child{
      margin-right: 0;
    }
  }
}

.verifybtnsGrp {
  padding: 15px 30px;
  text-align: right;

  @media (max-width: 767px) {
    padding: 15px;
  }

  h3 {
    font-size: 22px;
  }
}

.profileListDetails {
  border-bottom: 1px solid #eeeeee;
  padding: 30px 30px 5px;

  @media (max-width: 767px) {
    padding: 15px;
  }

  h5 {
    margin-bottom: 25px;
    font-size: 15px;
    color: #000000;

    @media (max-width: 767px) {
      margin-bottom: 10px;
    }

    span {
      display: block;
      color: #00000082;
      margin-bottom: 10px;

      @media (max-width: 767px) {
        margin-bottom: 5px;
      }
    }

    a {
      color: #1480cd;
    }
  }
}

.operationalHours-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px 20px;

  li {
    font-size: 15px;
    color: #000000;
    width: calc(100% / 3);
    margin-bottom: 10px;
    padding: 0 5px;
    display: flex;

    @media (max-width: 1360px) {
      font-size: 14px;
    }

    @media (max-width: 1199px) {
      width: calc(100% / 2);
    }

    @media (max-width: 567px) {
      width: 100%;
    }

    span {
      font-weight: 700;
      width: 85px;
      display: inline-block;
    }
  }
}

.managmentCard {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 575px) {
    display: block;
  }

  figure {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #eeeeee;
    margin-right: 25px;
    flex: none;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .ownerDetails {
    width: 100%;
  }

  h5 {
    font-size: 16px;
    color: #000000;
    font-weight: 700;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h6 {
    font-size: 15px;
    font-weight: 400;
    margin: 10px 0;
  }

  p {
    font-size: 15px;
    color: #000000;
  }
}

.userCard {
  text-align: center;
  margin-bottom: 15px;

  figure {
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #eeeeee;
    margin: auto;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  h5 {
    font-size: 16px;
    color: #000000;
    font-weight: 700;
    margin-top: 10px;
  }

  h6 {
    font-size: 15px;
    font-weight: 400;
    margin: 10px 0;
  }

  button:focus {
    color: #000000 !important;
    border: 1px solid #eeeeee;
    background: #fff;
  }

  button:hover {
    text-decoration: none;
    background: #edb457;
    color: #ffffff !important;
    border-color: #edb457;
  }
}

// profile

// media
.mediaContent {
  min-height: 50vh;
}

.mediaLinks a {
  color: #1890ff !important;
  margin-bottom: 10px;
  display: block;
}

.mediaPhotocard {
  border-radius: 10px;
  height: 146px;
  overflow: hidden;
  margin-bottom: calc(var(--bs-gutter-x));

  figure {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.mediaVideoCard {
  border-radius: 10px;
  height: 146px;
  overflow: hidden;
  position: relative;
  margin-bottom: calc(var(--bs-gutter-x));

  .videoContainer {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  button {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    background: none;
    border: none;
    width: 30px;
    height: 30px;
  }
}

// media

// Contractor

.cmnTab {
  .ant-tabs-nav {
    background-color: #0091d4;
    border-radius: 5px;
  }

  .ant-tabs-tab {
    font-size: 14px;
    font-weight: 700;
    margin: 3px 20px 1px;
    border-bottom: 2px solid #ffffff00;

    .ant-tabs-tab-btn {
      color: #ffffffcc;
    }

    // &.ant-tabs-tab-active {
    //   position: relative;
    //   &::after {
    //     position: absolute;
    //     content: "";
    //     width: 100%;
    //     height: 2px;
    //     border-radius: 10px;
    //     background-color: #fff;
    //     bottom: -2px;
    //   }
    //   .ant-tabs-tab-btn {
    //     color: #fff;
    //   }
    // }
  }

  .ant-tabs-ink-bar {
    background: #ffffff;
    border-radius: 15px;
    height: 3px;
  }

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-more,
  .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-more {
    color: #fff;
  }

  // .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  // .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  //   overflow: auto;
  // }
}

// Contractor

// blog

.blogCard {
  background-color: #fafafa;
  border: 1px solid #eeeeee;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 5px;

  .blogCardHead {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 24px;
      color: #231f20;
      font-weight: 700;
    }
  }

  p {
    font-size: 14px;
    margin-bottom: 0;
  }
}

// blog

// setting

.settingsCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: #e9f1f7;
  padding: 30px 20px;
  border-radius: 5px;

  @media (max-width: 575px) {
    padding: 15px;

    .ant-form {
      width: 100%;
    }

    button {
      margin-left: 0;
      margin-top: 15px;
    }
  }

  .ant-row {
    margin: 0;

    @media (max-width: 575px) {
      display: block;
    }

    .ant-col {
      flex: 1 1 auto;
      max-width: 100%;
    }
  }

  label {
    padding-bottom: 0;
    margin-top: 3px;
    font-size: 14px;
  }

  .ant-input-number {
    border-radius: 50px;
    overflow: hidden;
    box-shadow: 0 3px 24px rgb(0 0 0 / 4%);
    border: none;
    padding: 4px 0;
    width: 100px;
    display: block;
  }

  .ant-form-item-label {
    color: #000000;
    font-weight: 700;
    padding-bottom: 0;
  }

  .ant-form-item-explain {
    @media (min-width: 576px) {
      position: absolute;
      white-space: nowrap;
      bottom: -28px;
    }

    .ant-form-item-explain-error {
      margin-left: 0;
    }
  }

  .cmn-input input {
    border: none;
    background: #ffffff;
    box-shadow: 0 3px 24px #0000000a;
    font-family: sans-serif;
    width: 85px;
  }

  .ant-form-item-control {
    flex: inherit;
  }
}

// setting

// rating and review
.ratingAccordion {
  .ant-collapse .ant-collapse-item .ant-collapse-header {
    padding: 15px 25px;
    align-items: center;

    @media (max-width: 767px) {
      padding: 15px;
    }
  }
}

.userAccordion {
  .ant-collapse-header {
    background: #f0f5f8;

    .ant-collapse-arrow {
      color: #abcede;
      font-size: 16px !important;
    }
  }

  .userAccordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;

    .userimg-wrp {
      display: flex;
      align-items: center;

      figure {
        width: 32px;
        height: 32px;
        margin-right: 8px;
        flex: none;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      h6 {
        font-size: 14px;
        color: #000000;
        font-weight: 700;
      }
    }

    .ratings-wrp {
      margin-right: 10px;
    }
  }

  .ant-collapse-content-box {
    h5 {
      font-size: 14px;
      color: #000000;
      font-weight: 700;
      margin: 10px 0 15px;
    }

    p {
      font-size: 14px;
      color: #000000;
      margin-bottom: 15px;
    }

    h6 {
      font-size: 14px;
      color: #0091d4;
      font-weight: 700;
    }

    a {
      font-size: 14px;
      color: #0091d4;
      margin-bottom: 15px;
      display: inline-block;
    }

    h4 {
      font-size: 14px;
      color: #0091d4;
      font-weight: 700;
      margin: 10px 0 15px;
    }
  }

  .ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
    padding-top: 4px;
    background: #f5f7f9;
    padding: 15px 25px;

    @media (max-width: 767px) {
      padding: 15px;
    }
  }
}

.accordionHeadReview {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .ratings-wrp h6 {
    color: #fff;
  }

  .ratings-wrp ul .anticon-star {
    color: #fff;
  }
}

.ratings-wrp {
  display: flex;
  align-items: center;

  h6 {
    font-size: 13px;
    color: #000000;
    margin-right: 8px;
    margin-top: 1px;
  }

  ul {
    display: flex;
    align-items: center;

    li {
      display: flex;
      align-items: center;
      margin-right: 5px;

      &.deactive {
        .anticon-star {
          opacity: 0.4;
        }
      }
    }

    .anticon-star {
      color: #ffbd24;
      font-size: 12px;
    }
  }
}

.ratingQuestions {
  padding: 25px 25px;

  @media (max-width: 767px) {
    padding: 15px;
  }

  &>ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -15px;

    &>li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #0091d4;
      font-weight: 700;
      padding: 0 15px;
      flex: 1 0 auto;
      width: auto;
      max-width: 100%;
    }
  }
}

// rating and review

// --------------------- VENDOR ---------------------

// --------------------- USER ---------------------

.noOfdaysDetails {
  text-align: center;

  span {
    cursor: pointer;
  }
}

.daysDetailsOverlay {
  .ant-popover-inner-content {
    padding: 0;
  }
}

.daysDetailsContent {
  max-height: 260px;
  overflow: auto;

  div {
    padding: 12px 16px;
    border-bottom: 1px solid #00000012;

    &:last-of-type {
      border: none;
    }
  }

  h5 {
    font-size: 13px;
    color: #000000;
    margin-bottom: 5px;

    span {
      font-weight: 700;
    }
  }

  p {
    font-size: 13px;
    color: #000000;
  }
}

.userProposalDetails-wrp {
  .vendorCard {
    margin: 30px 0;
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: space-between;

    p {
      display: none;
      margin-bottom: 0px;
    }

    h6 {
      margin-bottom: 0;
      font-size: 13px;
    }

    h5 {
      font-size: 16px;
    }

    figure {
      width: 86px;
      height: 86px;
      margin: 0;
    }

    .divider {
      margin-right: auto;
      margin-left: 15px;
    }
  }
}

// rating card
.ratingCard {
  background-color: #fff;
  border: 1px solid #f8e4c480;
  padding: 5px;
  border-radius: 20px;
  margin-bottom: 15px;

  figure {
    height: 138px;
    width: 100%;
    border-radius: 17px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h4 {
    font-size: 16px;
    color: #edb258;
    font-weight: 700;
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    svg {
      margin-left: 5px;
    }
  }
}

.ratingCaption {
  padding: 16px 12px 12px 12px;
}

.rating-wrp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.starGrp {
  display: flex;
  align-items: center;

  li {
    display: flex;
    align-items: center;
    margin-right: 2px;
  }

  .anticon-star {
    color: #ffbd24;
    font-size: 11px;
  }
}

.reviewCount {
  display: flex;
  align-items: center;

  span {
    font-size: 12px;
    color: #ffffff;
    font-weight: 700;
    background-color: #d62c81;
    border-radius: 5px;
    display: flex;
    min-width: 32px;
    height: 18px;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    padding-top: 3px;
  }

  em {
    color: #dcc39a;
    font-style: normal;
    font-size: 12px;
  }
}

// rating card

// pagination

.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
  padding-left: 6px;
  font-size: 14px;
}

// pagination

.cmnAccordion {
  >.ant-collapse {
    background-color: #fafafa00;

    >.ant-collapse-item {
      border: none;
      margin-bottom: 15px;

      >.ant-collapse-header {
        background-color: #0091d4;
        border-radius: 15px;
        padding: 10px 16px;
        color: #fff;
        font-weight: 700;

        &>div:first-child {
          order: 2;
          margin-left: auto;
        }
      }

    
    }

    >.ant-collapse-item-active {
      .ant-collapse-header {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
.ant-collapse-content-box {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

// survey result

.vendorsurveyContent {
  @media (max-width: 567px) {
    .searchbox {
      width: 100%;
      margin-top: 10px;
    }
  }
}

.clientsFilter {
  @media (min-width: 768px) {
    padding-bottom: 25px;
  }

  h3 {
    font-size: 16px;
    color: #000000;
    font-weight: 700;
  }
}

.dateFilters {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  h4 {
    font-size: 14px;
    color: #00000080;
    margin: 0 20px;
  }

  .cmnSelect-form {
    width: 200px;
    max-width: 100%;
  }

  .blueBtn {
    margin: 0 15px;
  }

  @media (max-width: 567px) {
    display: block;

    .cmnSelect-form {
      width: 100%;
      margin: 10px 0;
    }

    h4 {
      text-align: center;
    }

    .blueBtn {
      margin: 15px 15px 0 0;
    }
  }
}

.countDisplay {
  font-size: 20px;
  color: #000000;
  font-weight: 700;
  background-color: #ededed;
  min-width: 100px;
  text-align: center;
  height: 36px;
  line-height: 36px;
  border-radius: 10px;
  display: inline-block;
}

// survey result

// --------------------- USER ---------------------

// --------------------- QUESTIONAIRES ---------------------

.uploadInput {
  border: 1px solid rgba(20, 128, 205, 0.2);
  background: rgba(246, 246, 246, 0);
  border-radius: 5px;
  padding: 8px 13px;
  font-size: 14px;
  border-radius: 50px;
  color: #000000;

  .uploadSpan {
    display: flex;
    align-items: center;

    .ant-upload {
      color: #1480cd;
      display: inline-flex;
    }
  }

  .ant-upload.ant-upload-select {
    margin-left: auto;
    display: inline-block;
    order: 2;
  }

  .ant-upload-list {
    max-width: 88%;
  }

  .ant-upload-list-item {
    margin: 0;

    .ant-upload-list-item-name {
      color: #1480cd;
      text-decoration: underline;
      text-overflow: ellipsis;
    }
  }

  .ant-upload-text-icon {
    display: none;
  }

  .ant-tooltip {
    display: none;
  }
}

.questioncreateCard {
  h4 {
    font-size: 16px;
    color: #000000;
    font-weight: 700;
    margin-bottom: 20px;
  }

  h5 {
    font-size: 14px;
    color: #000000;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .createCard {
    border-radius: 5px;
  }

  .borderedBox {
    border-radius: 5px;
  }
}

.emoji-wrp {
  margin-top: 16px;
}

// --------------------- QUESTIONAIRES ---------------------

// --------------------- BLOG ---------------------

.blogTag {
  background-color: #cef0ff;
  border-radius: 25px;
  font-size: 12px;
  color: #000000;
  border: none;
  padding: 2px 10px;
  margin-bottom: 2px;
}

.blogDetailCard {
  background-color: #fcfcfc;
  border: 1px solid #eeeeee;

  h2 {
    font-size: 32px;
    color: #231f2066;
    font-weight: 700;
    margin-bottom: 25px;

    @media (max-width: 767px) {
      font-size: 25px;
      margin-bottom: 15px;
    }
  }

  @media (max-width: 767px) {
    .verifybtnsGrp {
      text-align: left;
    }
  }
}

// --------------------- BLOG ---------------------

// --------------------- MASTER MODULE ---------------------

.templateFig {
  width: 42px;
  height: 42px;
  border-radius: 5px;
  overflow: hidden;
  margin: auto;

  img {
    width: 100%;
    height: 100%;
  }
}

.tandcCard {
  background-color: #fcfcfc;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  box-shadow: 0px 3px 6px 0 #0000000d;
  margin-top: 10px;

  h2 {
    font-size: 32px;
    color: #231f2066;
    font-weight: 700;
    margin-bottom: 25px;

    @media (max-width: 767px) {
      font-size: 25px;
      margin-bottom: 15px;
    }
  }

  .verifybtnsGrp {
    padding: 15px 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.0705882353);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 567px) {
      justify-content: center;
    }
  }

  .cmn-input input {
    border-radius: 0;
  }
}

.categorycreateCard {
  border-radius: 5px;

  h5 {
    font-size: 14px;
    color: #000000;
    margin: 25px 0 15px 0;
  }
}

.categoryMedia {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 88px;
  position: relative;

  .mediaVideoCard,
  .mediaPhotocard {
    width: 88px;
    height: 88px;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  .deleteBtn {
    border: none;
    font-size: 13px;
    border-radius: 100px;
    position: absolute;
    right: -9px;
    width: 25px;
    min-width: auto;
    padding: 0;
    height: 26px;
    top: -8px;
    background: red;
    color: #fff;
    line-height: 12px;
    z-index: 11;
  }
}

.quillEditor-wrp {
  .quill {
    border: 1px solid #1480cd33;
  }

  .ql-toolbar.ql-snow {
    border: none;
    border-bottom: 1px solid #1480cd33;
    font-family: "Amiri", serif;
    margin-bottom: 10px;
  }

  .ql-container.ql-snow {
    border: none;
    font-family: "Amiri", serif;
    padding: 15px;
  }

  .ql-editor {
    min-height: 250px;
    max-height: 500px;
    padding: 5px !important;
  }

  .ql-snow .ql-editor img {
    @media (min-width: 768px) {
      max-width: 50%;
      max-height: 100vh;
    }
  }

  .ql-snow .ql-tooltip.ql-flip {
    left: 5px !important;
    top: -9px !important;
  }
}

// --------------------- MASTER MODULE ---------------------//

//---------------------emp1379-desiadmin---------------------//
.VideoPopup {
  h3 {
    text-align: center;
  }

  .videomodalBody {
    text-align: center;
  }

  .Videobtn {
    margin-top: 22px;
  }
}

.Taglne {
  word-break: break-all;
}

.filter-div {
  float: right;
  margin-right: 5px;

  button {
    background-color: #009fde;
    border-radius: 50%;
    border: none;
    width: 36px;
    height: 36px;
  }
}

.overlay-profile {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 111;
}

.Edit-popup {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: fit-content;
  overflow: auto;
  width: 750px;
  z-index: 2100;
  background-color: #fff;
  border-radius: 5px;
  padding: 45px;

  @media (max-width: 767px) {
    width: 100%;
  }

  .ant-modal-footer {
    background: transparent;
    border-top: none;
    margin-top: 30px;
  }

  h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;

    @media (max-width: 767px) {
      font-size: 22px;
    }
  }

  .Edit-popupcontent {
    label {
      margin-top: 30px;
      margin-bottom: 7px;
      opacity: 51%;
      font-size: 12px;
    }

    h5 {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 11px;

      @media (max-width: 767px) {
        margin-bottom: 8px;
      }
    }

    small {
      color: #000;
      font-weight: 400;
      font-size: 16px;
    }
  }

  &.TodoPopupWrap {
    h3 {
      margin-bottom: 0;
    }

    .TodoPopup-content {
      h5 {
        font-size: 15px;
        font-weight: 400;
        color: #707070;
        margin-top: 35px;
      }

      small {
        font-size: 15px;
        font-weight: 400;
        color: #000000;
      }

      p {
        font-size: 15px;
        font-weight: 400;
        color: #000000;
      }
    }
  }
}

.play-video {
  display: flex;
  align-items: center;

  .video-btn {
    width: 48px;
    height: 41px;
    border-radius: 4px;
    position: relative;
    border: none;
    padding: 0;
    flex: none;

    .tumbnail-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .play-btn {
      position: absolute;
      top: 8px;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  p {
    font-size: 15px;
    font-weight: 400;
    color: #009fde;
    margin-left: 10px;

    @media (max-width: 991px) {
      max-width: 340px;
    }

    @media (max-width: 640px) {
      margin-left: 8px;
      font-size: 14px;
    }
  }
}

.btnf {
  button {
    &:focus {
      border: none;
      outline: none;
    }
  }

  .eyeiconvend {
    margin-top: 5px;
  }
}

.dashboard-container {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 3px 10px 0 rgb(9 9 9 / 8%);
  background-color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 190px);

  h2 {
    color: #1480cd;
    font-size: 36px;
    font-family: $Allura;
  }
}

.mt_media {
  margin-top: 3rem !important;
  cursor: pointer;
}

.full-width {
  width: 100% !important;
  max-width: 600px;

  img {
    width: 100%;
    padding-bottom: 24px !important;
    max-height: 600px;
    max-width: 600px;
    height: 100%;
    object-fit: contain;
    object-position: right;
  }

  .ant-modal-close {
    width: 35px;
    height: 35px;
    position: absolute;
    top: 8px;
    right: 4px;

    .ant-modal-close-x {
      color: #000 !important;
      font-size: 17px;
    }
  }

  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }
}

.Edit-popup.updateP {
  width: 600px;
  padding: 30px 35px 30px;

  @media (max-width: 767px) {
    width: 100%;
  }

  .cmnSelect-form {
    border: 1px solid;
    border: 1px solid rgb(20 128 205 / 35%) !important;
    border-radius: 24px;
  }
}

.Marg-B {
  margin-bottom: 10px;
}

.video-upload {
  position: relative;

  .ant-form-item-control-input {
    min-height: auto;
  }

  .ant-upload-list-item {
    height: auto;
    margin-top: 0;
  }

  
  .profile-thumbanail {
    position: relative;
    display: flex;
    justify-content: left;
    margin: 10px 10px 10px 0px;
    width: 100px;
    .fullPageLoader {
      position: absolute;
      background:rgb(82 82 82 / 28%);
      border-radius: 10px;
      width: 100px;
      height: 100%;
    }
    .close-thumb {
      position: absolute;
      right: -16px;
      top: -8%;
    }

    img {
      @media (max-width: 727px) {
        width: auto;
      }
    }

    button {
      background: none;
      border: none;
    }
  }

  .play-thumb {
    position: absolute;
    bottom: 35%;
    left: 28%;
  }

  .ant-upload-list-item-info {
    display: none;
  }

  .ant-form-item {
    margin: 0;
  }

  h5 {
    color: rgba(0, 0, 0, 0.7803921569);
    font-size: 12px;
    margin-bottom: 10px;
  }
}

// data 22
.image-upload {
  .ant-form-item {
    margin: 0;
  }

  .ant-upload-list-item {
    border: none;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
  }

  .ant-form-item-control-input-content {
    flex: 0;
    max-width: 100%;
    width: 100px;
    position: relative;
  }

  .ant-upload-list-item-name {
    display: none;
  }

  .ant-upload-list-item-card-actions {
    display: none;
  }

  .ant-tooltip {
    display: none;
  }

  .ant-upload-list-picture {
    .ant-upload-list-item-image {
      max-width: 100px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .close-thumb {
    position: absolute;
    right: -18px;
    top: -11px;

    button {
      background: none;
      border: none;
    }
  }

  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
  .ant-upload-list-picture .ant-upload-list-item-thumbnail {
    width: 100px;
    height: 100px;
  }

  h5 {
    color: rgba(0, 0, 0, 0.7803921569);
    font-size: 12px;
    margin-bottom: 10px;
  }
}

.required {
  color: red;
}

.edit-pen-button {
  background-color: #009fde14;
  border-radius: 4px;
  border-color: #009fde14;
  padding: 0 4px;
  height: 24px;
}

.enable-switch {
  color: #20222d;
  margin-right: 30px;

  button {
    margin-right: 8px;
  }
}

.vendorEdit-profile {
  width: 800px !important;
}

.upload-vendor-profile {
  .upload-vendor-imgbox {
    position: relative;
    width: 140px;
    height: 140px;
    margin: auto;
  }

  figure {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 0 3px 22px #52525217;
    overflow: hidden;
  }

  .cmnBtn {
    position: absolute;
    right: 0;
    width: 28px;
    min-width: auto;
    padding: 0;
    height: 28px;
    top: 8px;
  }

  .ant-upload.ant-upload-select {
    display: inline;
  }
}

.owner-profile-upload {
  text-align: center;
  margin-bottom: 26px;

  figure {
    width: 226px;
    height: 226px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 20px;
    background-color: #ecf6fc;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h6 {
    font-size: 16px;
    color: #20222d9c;
    margin: 5px 0;
  }

  .ant-upload {
    color: #1b80ce;
    font-weight: 700;
    font-size: 12px;
    text-decoration: underline;
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 226px;
    height: 226px;
    border-radius: 50%;
    overflow: hidden;
    margin: auto 0;
  }

  .ant-upload-list-picture-card-container {
    width: 226px;
    height: 226px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 20px;
  }

  .ant-upload-list-picture-card .ant-upload-list-item {
    border: none;
    padding: 0;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    object-fit: cover;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-info:before {
    left: 0;
  }

  .ant-upload-list-item-card-actions-btn:hover {
    background: none;
    border: none;
  }

  .ant-tooltip {
    display: none !important;
  }
}

.editownerModal {
  width: 780px !important;
}

.operationalHoursModal {
  width: 750px !important;

  .enable-switch {
    margin-right: 0;
    margin-top: 20px;
  }
}

.sameEveryday-wrp {
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    flex-wrap: wrap;

    @media (max-width: 567px) {
      justify-content: center;
      margin-bottom: 10px;
    }
  }

  li {
    display: flex;
    margin-bottom: 15px;
    align-items: center;

    .daySpan {
      margin-right: 5px;
      flex: none;
      cursor: pointer;
    }

    &.disabledDay {
      .daySpan {
        color: #707070;
        background-color: #e6e2e3;
      }

      .cmnInput input {
        background-color: #e6e2e3;
      }
    }

    &.errorDay {
      .daySpan {
        border: 1px solid red;
      }
    }
  }

  @media (max-width: 400px) {
    .timeSlot {
      width: 100%;
    }

    .divider {
      width: 100%;
      text-align: center;
    }
  }
}

.daySpan {
  font-size: 14px;
  color: #ffffff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #1480cd;

  @media (max-width: 1600px) {
    width: 40px;
    height: 40px;
    font-size: 13px;
  }
}

.timeSlot-wrp {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 400px) {
    width: 100%;
  }

  .divider {
    display: inline-block;
    margin: 0 8px;
    color: #2c9fda;
  }
}

.timeSlot {
  width: 125px;

  .cmnInput {
    margin-bottom: 0;

    input {
      padding: 7px 15px 5px 15px;
      font-size: 14px;
    }
  }

  .arrow-wrp {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 15px;
    top: 4px;
  }

  button {
    background: none;
    border: none;
    padding: 0;
    display: flex;

    svg {
      width: 10px;
    }

    &.upArrow {
      transform: rotate(90deg);
    }

    &.downArrow {
      transform: rotate(270deg);
    }
  }
}

.differentDay-wrp {
  ul {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    display: flex;
    margin-bottom: 15px;
    width: 50%;
    align-items: center;

    @media (max-width: 567px) {
      width: 100%;
    }

    @media (max-width: 400px) {
      padding-bottom: 15px;
    }

    .daySpan {
      margin-right: 15px;
      flex: none;
      cursor: pointer;
    }

    &.disabledDay {
      .daySpan {
        color: #707070;
        background-color: #e6e2e3;
      }

      .cmnInput input {
        background-color: #e6e2e3;
        border-color: transparent;
      }
    }

    &.errorDay {
      .daySpan {
        border: 1px solid red;
      }
    }
  }

  @media (max-width: 400px) {
    .timeSlot {
      width: 100%;
    }

    .divider {
      width: 100%;
      text-align: center;
    }
  }
}

.fullwidth {
  width: 100%;
}

.tagButton-grp {
  display: flex;

  button {
    min-width: 85px;
    margin-top: 26px;
  }
}

.AddcreateCardHead {
  justify-content: space-between;

  .createCardText {
    h3 {
      font-size: 22px;
      font-weight: bold;
    }
  }

  .createCardBtn {
    button {
      &:first-child {
        margin-right: 10px;
      }
    }
  }
}

.tagModal {
  .searchbox .ant-input-group {
    border: 1px solid rgba(112, 112, 112, 0.1019607843);
  }
}

.checkbox-table {
  .ant-table-tbody>tr>td {
    background: #f5f7f900;
    height: 45px;
  }

  .ant-table-body tr {
    box-shadow: none;
  }

  .ant-table {
    min-height: auto;
  }

  .ant-table-content {
    overflow: auto;

    @media (min-width: 768px) {
      height: 50vh;
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: #a4a4a538;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #a6a6a6;
      border-radius: 3px;
    }
  }
}

.deleteBtnStyle {
  background-color: #fcdede;

  path {
    stroke: red;
    stroke-width: 0.9;
  }
}

.ClearBtn {
  margin-bottom: 0 !important;
  margin-top: 7px;
  cursor: pointer;

  svg {
    width: 25px;
    height: 25px;
    fill: #0090d2;
  }
}

.text-Center {
  text-align: center;
}

// --------------------- MY SCHEDULE ---------------------
.schedule-tablefilter {
  .tabList {
    li {
      min-width: 83px;
    }
  }

  .cmnSelect-form .ant-picker {
    height: 36px;
  }
}

.schedule-wrp {
  .schedule-head {
    background-color: #0091d4;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-size: 16px;
    color: #ffffff;
    padding: 18px 30px;

    @media (max-width: 767px) {
      padding: 15px;
    }

    span {
      font-weight: 700;
    }
  }

  .schedule-body {
    background-color: #ffffff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 30px;

    @media (max-width: 767px) {
      padding: 15px;
    }

    .schedule-body-in {
      border: 1px solid #eeeeee;
      border-radius: 5px;
      padding: 30px;

      @media (max-width: 767px) {
        padding: 15px;
      }

      .ant-pagination {
        text-align: right;
      }
    }
  }

  .cmnSelect-form {
    .ant-select-selector {
      box-shadow: 0 3px 24px #0000001a;
    }
  }
}

.scheduleCard {
  position: relative;
  padding: 20px;
  border-radius: 5px;
  background: rgb(109, 157, 197);
  background: linear-gradient(180deg, rgba(109, 157, 197, 1) 0%, rgba(51, 102, 145, 1) 100%);
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 128px;

  @media (max-width: 1360px) {
    padding: 12px;
  }

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url("../assets/background-images/schedule-bg.png");
    pointer-events: none;
    background-size: cover;
  }

  &.completed-schedule {
    background: rgb(3, 181, 170);
    background: linear-gradient(180deg, rgba(3, 181, 170, 1) 0%, rgba(9, 96, 90, 1) 100%);
  }

  &.cancelled-schedule {
    background: rgb(249, 88, 152);
    background: linear-gradient(180deg, rgba(249, 88, 152, 1) 0%, rgba(148, 0, 74, 1) 100%);
  }

  &.not-scheduled {
    background: rgb(109, 157, 197);
    background: linear-gradient(180deg, rgba(109, 157, 197, 1) 0%, rgba(51, 102, 145, 1) 100%);
  }

  &.notAttended-schedule {
    background: rgb(154, 103, 252);
    background: linear-gradient(180deg, rgba(154, 103, 252, 1) 0%, rgba(83, 4, 165, 1) 100%);
  }

  &.ongoing-schedule-schedule {
    background: rgb(25, 169, 235);
    background: linear-gradient(180deg, rgba(25, 169, 235, 1) 0%, rgba(0, 73, 106, 1) 100%);
  }

  .scheduleDetails {
    padding-right: 8px;
  }

  h6 {
    font-size: 16px;
    margin-bottom: 8px;
    word-break: break-word;
  }

  h4 {
    font-size: 18px;
    font-weight: 700;
  }

  .iconBtn {
    height: 27px;
    min-width: 27px;
    outline: 8px solid #ffffff30;

    &.rightPosition {
      svg {
        transform: rotate(180deg);
      }
    }

    &:focus {
      background: #fff;
      outline: 8px solid rgba(255, 255, 255, 0.1882352941);
      border: none;
    }
  }
}

.scheduleBtns {
  display: flex;
  align-items: center;

  .ant-btn {
    min-width: 120px;

    @media (max-width: 1360px) {
      min-width: auto;
      padding: 7px 13px;
    }
  }

  .cmnBtn {
    color: #0b81b7;
    background-color: #ffffffe6;

    &:focus,
    &:active,
    &:hover {
      background: #edb457;
    }
  }

  .outlineBtn {
    border-color: #ffffff;
    color: #ffffff;
    background: none;
    margin-left: 8px;
  }
}

.schedule-height {
  @media (min-width: 768px) {
    min-height: 240px;
  }
}

// --------------------- MY SCHEDULE ---------------------

.uploaded-document-list {
  margin: 0 0 20px 20px;

  li {
    &::marker {
      color: #444444;
    }
  }

  span {
    margin-left: 10px;
    position: relative;
    top: 4px;

    button {
      height: 26px;
      min-width: 26px;

      svg {
        width: 10px;
      }
    }
  }
}

.hide-pagination-numbers {

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev,
  .ant-pagination-item {
    display: none;
  }
}

.ant-modal-mask {
  z-index: 10001;
}

.ant-modal-wrap {
  z-index: 10001;
}

.ant-select-dropdown {
  z-index: 11111;
}
.specializationButon
{
  width: 35%;
  padding-right: 20;
  margin-right: 63%;
}

.specializationSelect
{
  margin-left: 50%;
    padding: 3%;
}
.specializationContent {
  .specializationContent-select {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
  }

  .cmnBtnVendor {
    display: flex;
    justify-content: flex-end;
  }


}

.AddVendorModal {
  max-width: 680px;
  width: 680px !important;

  .AddVendorModalBody {
    padding: 0 20px;

    li {
      margin-bottom: 20px;

      .title-box {
        color: rgba(0, 0, 0, 0.40);
        font-size: 16px;
        font-weight: 400;
        display: block;
        margin-bottom: 12px;
      }

      label {
        span {
          color: #000;
        }
      }
    }
  }
}

.profileListDetail {
  ol {
    padding: 20px;

    li {
      color: rgba(0, 0, 0, 0.40);
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 20px;

      span {
        color: #000;
        font-size: 16px;
        font-weight: 400;
        display: block;
      }
    }
  }
}


.profileListDetailedit {
  border-bottom: 1px solid #eeeeee;
  padding: 30px;

  .editBlock {
    svg {
      margin: auto;
      display: block;
      margin-right: 0;
      cursor: pointer;
    }
  }

  ol {
    padding: 0 20px;

    li {
      color: rgba(0, 0, 0, 0.40);
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 20px;

   
      .title-box {
        color: #000;
        font-size: 16px;
        font-weight: 400;
        display: block;
      }
    }
  }
}

.uplodingsuccess {
  color: #00BA00;
  font-size: 16px;
  font-weight: bold;
  position: relative;
}

.vendorQuestionModal{
  .ant-checkbox-wrapper+.ant-checkbox-wrapper {
    margin-left: 0px ;
    margin-right: 8px;
}
}
.photoOptions{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  li{
    width: calc(100% / 4 - 8px);
    @media (max-width: 991px) {
      width: calc(100% / 2 - 8px);
    }
  }
  figure{
    width: 100%;
    img{
      max-width: 100%;
    }
  }
}

.quizResponseAccordion {
  >.ant-collapse {
    background-color: #fafafa00;

    >.ant-collapse-item {     
      margin-bottom: 15px;
      border-radius: 15px;
      overflow: hidden;
      border: 1px solid #cccccc26;

      >.ant-collapse-header {
        background-color: #0091d4;      
        padding: 10px 16px;
        color: #fff;
        font-weight: 700;

        &>div:first-child {
          order: 2;
          margin-left: auto;
        }
      }

    
    }

    >.ant-collapse-item-active {
      .ant-collapse-header {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
.ant-collapse-content-box {
  padding: 10px 16px;
  }
}

.agreement-section {
  max-height: 65vh;
  overflow: auto;
  ol {
    margin-left: 15px;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 10px;
  }
}